.todo_list_main_page {
    display: flex;
    flex: 1 1 0;
    min-height: 1px;
}

.flex-2{
    flex: 2;
}

.p-2{
    padding: 1rem;
}

.backdrop{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;

}

@media screen and (max-width: 768px) {
    .todo_list_main_page {
        display: flex;
        min-height: 1px;
        flex-direction: column;
    }
}