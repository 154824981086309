.sidebar_section {
  background: linear-gradient(#303b8e, #12a29d);
  height: 100vh;
  color: #fff;
  position: sticky;
  top: 0;
  left: 0;
}
.mobile_nav {
  display: none;
  position: relative;
}

.sidebar_logo {
  max-height: 90px;
  padding: 20px 20px;
}

.sidebar_section ul {
  list-style: none;
  margin: 20px 20px;
}

.sidebar_section ul li {
  margin-bottom: 15px;
}

.sidebar_section svg path {
  stroke: rgb(215, 215, 215);
}

.sidebar_section svg {
  margin-right: 10px;
}

.sidebar_section div {
  border-bottom: 1px solid rgb(165, 165, 165);
}
.sidebar_section div p {
  margin: auto 20px;
  margin-top: 20px;
  margin-bottom: -10px;
  color: rgb(215, 215, 215);
}

.client_modal {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translatex( -50%);
  color: #000;
  width: 70%;
  border-radius: 20px;
  box-shadow: 1px 1px 2px 0px #0000004a;
  background: #fff;
  z-index: 99999;
  /* min-height: 90vh; */
}
.client_modal div {
  /* border: none !important; */
}
.client_modal h2 {
  border-bottom: 1px solid #a5a5a5;
  padding: 20px;
  margin-bottom: 20px;
}
.client_modal h2 svg {
  float: right;
}
.client_modal input,
.css-1s2u09g-control {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background-color: var(--color-background-weak);
  border-width: 1px;
  border-style: solid;
  border-color: #cfcbcb;
  border-radius: 6px;
  padding: 6px 12px;
  box-sizing: border-box;
  display: block;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.client_modal form {
  padding: 20px;
}

.modal_body {
  overflow-y: scroll;
  height: 70vh;
}

.client_modal button {
  padding: 10px 20px;
  border: none;
  margin: 20px auto;
  background: linear-gradient(#303b8e, #12a29d);
  border-radius: 10px;
  color: #fff;
}

.my_task {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .sidebar_section {
    display: none;
  }
  .mobile_nav {
    display: block;
    padding: 20px;
    /* background: linear-gradient(#303b8e, #12a29d); */
    /* color: white; */
    font-weight: 700;
    font-size: 32px;
    position: relative;
  }
  .mobile_trail {
    position: fixed;
    top: 60vh;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .mobile_nav__links {
    position: sticky;
    top: 10px;
    display: flex;
    text-align: center;
    font-size: 12px;
    justify-content: space-evenly;
    font-weight: 400;
    padding: 5px 20px;
  }

  .mobile_nav__links a{
    width: 50%;
    padding: 15px 0;
  }
  .mobile_nav__backdrop_blue{
    position: fixed;
    width: 100%;
    height: 40vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -10;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(253.15deg, #235f93 -6.82%, #49aaad 152.86%);
  }
}

.svg_color_blue{
  background: linear-gradient(to right, #14a19d 0%, #2c4c90 77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
