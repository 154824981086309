.ground_worker_task_div {
  display: grid;
  grid-template-columns: calc(66% - 15px) calc(33% - 15px);
  column-gap: 30px;
  width: 100%;
  position: relative;
  /* margin-bottom: 20px; */
}

.date_userClient {
  color: #2d2d2d;
  font-size: 12px;
  display: block;
}

.ground_worker_task_div svg {
  background-color: #fff;
  font-size: 35px;
  padding: 7px;
  border-radius: 50%;
  color: #1764d8;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%) !important;
}

/* .ground_worker_task_div::after{
    content: "";ground_worker_task_div
    position: absolute;
    bottom: 0;
    width: 80%;
    height: 2px;
    background-color: #b1b1b1;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
} */