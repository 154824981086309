.single_task {
  display: flex;
  margin-bottom: 10px;
}

.task_title_div {
  min-width: 50%;
  display: flex;
}
.task_complete_icon svg path {
  stroke: green;
  font-size: 30px;
  margin-right: 20px;

}
.task_complete_icon {
  margin-right: 20px;
}
.completed_task svg path {
  margin-right: 20px;
  stroke: #fb4f5e !important;
}

.task_details {
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  width: 40%;
  background: #fff;
  top: 0;
  right: 0;
  transition: all 1s linear;
  box-shadow: 0px 0 5px 0px rgb(205 205 205 / 50%);
}

.task_details__topbar {
  padding: 20px;
  display: flex;
  border-bottom: 1px solid #dedede;
}

.task_assignee_select {
  min-width: 200px;
  max-width: 300px;
}

.task_details__left_fields .task_details__left_span {
  min-width: 120px;
}


@media screen and (max-width: 768px) {
  .single_task {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
  }
  .task_title_div {
    min-width: 50%;
    display: flex;
    margin-bottom: 10px;

  }
}