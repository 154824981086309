@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}
body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #f4f4f4;
}
.color-success path{
  fill: green;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input{
 
  font-size: 16px;
  padding: 10px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}

input:focus{
  border-bottom: 2px solid #2c4a90 !important;
  outline: none;
}
label{
  font-size: 16px;
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem;
}

.d-none {
  display: none;
}
.w-50 {
  width: 50% !important;
}

.w-35 {
  width: 35% !important;
}

.d-flex {
  display: flex;
  align-items: center;
}

.slide-left {
  transition: all 1s linear;
  animation: 1s slide-left;
}
@keyframes slide-left {
  0% {
    right: -30%;
  }
  100% {
    right: 0%;
  }
}
.text-primary {
  color: #2c4a90;
}
[contentEditable="true"]:active,
[contentEditable="true"]:focus {
  border: 2px solid #1764d8;
  border-radius: 10px;
  outline: none;
  padding: 5px;
  padding-right: 30px;
}

.float-end {
  float: right;
}

.ml-auto {
  margin-left: auto;
}
.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.ml-5 {
  margin-left: 3rem;
}
.mr-3 {
  margin-right: 1.5rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.p-20px {
  padding: 20px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.badge {
  font-size: 14px;
  padding: 5px;
  border-radius: 10px;
  background-color: #dedede;
  text-align: center;
}

.badge-warning {
  background-color: rgba(244, 244, 55, 0.399);
}

.btn {
  padding: 10px 20px;
  border: none;
  margin: 20px auto;
  background: linear-gradient(#303b8e, #12a29d);
  border-radius: 10px;
  color: #fff;
}

input[type="date"] {
  padding: auto 10px !important;
  min-height: 38px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.d-grid-25-25-25-25 {
  display: grid;
  grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(
      25% - 15px
    );
  column-gap: 30px;
  row-gap: 60px;
}

.d-grid-33-33-33 {
  display: grid;
  grid-template-columns: calc(33.33% - 10px) calc(33.33% - 10px) calc(
      33.33% - 10px
    );
  column-gap: 30px;
  row-gap: 60px;
}
.d-grid-50-50 {
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  column-gap: 30px;
  row-gap: 60px;
}
.w-100 {
  width: 100% !important;
}

.mb-60 {
  margin-bottom: 60px;
}

.border-1px {
  border: 1px solid #2d2d2d;
}

.p-5 {
  padding: 3rem;
}

.text-center {
  text-align: center;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}
.card_hover h2 {
  font-weight: 500;
  color: #2d2d2d;
}

.card_hover h3 {
  font-weight: 500;
}
.align-item-end {
  align-items: flex-end;
}
.color-gradient {
  background: linear-gradient(to right, #14a19d 0%, #2c4c90 77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card_hover:hover,
.card_active {
  background: linear-gradient(45deg, #14a19d 10%, #2c4a90 90%) !important;
  color: white;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%) !important;
}
.card_hover:hover .color-gradient {
  -webkit-text-fill-color: #fff;
}

.card_active .color-gradient,
.card_hover:hover h2 {
  -webkit-text-fill-color: #fff;
  color: #fff;
}

.button_navpill {
  color: #000;
  background-color: white;
  margin-bottom: -2px;
  border-bottom: 3px solid #374099 !important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  border-radius: 25px;
}

.w-25 {
  width: 25%;
}

.p-2 {
  padding: 1rem;
}
.p-1 {
  padding: 0.5rem;
}

.w-15 {
  width: 15%;
}
.mr-2 {
  margin-right: 1rem;
}

.p-absolute {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translatex(-50%);
}
.w-90{
  width: 90%;
}
.p-relative {
  position: relative;
}
.font-bold{
  font-weight: 500;
}
.z-index9999 {
  z-index: 9999;
}

.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}

.active_nav_mobile {
  color: #303b8e;
  border-bottom: 2px solid #303b8e;
  background-color: transparent;
}

.justify-space-between {
  justify-content: space-between;
}

.inactive_nav_mobile {
  color: #7a7a7a;
  /* background-color: #fff; */
  background-color: transparent;
}

.inactive_nav_mobile:focus,
.inactive_nav_mobile {
  background-color: #fff;
}

.mt-2 {
  margin-top: 1rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.user_dashboard__heading {
  display: flex;
  display: inline-block;
  color: #fff;
  padding: 8px 20px;
  border-radius: 0 20px 20px 0;
  background: linear-gradient(45deg, #14a19d 10%, #2c4a90 90%) !important;
}

.progress_bar div div{
  background: linear-gradient(45deg, #14a19d 10%, #2c4a90 90%) !important;
}
.active_user_time {
  color: #fff;
  background-color: #14a19d;
  padding: 5px 10px;
  border-radius: 20px;
}

.mr-1 {
  margin-right: 0.5rem;
}

.d-grid-50-50-card {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  column-gap: 10px;
  row-gap: 10px;
}

.font-size-25px {
  font-size: 25px;
}
.client_overview__nav {
  padding: 10px 50px;
}

.client_overview__nav svg:last-child {
  font-size: 30px;
  cursor: pointer;
  float: right;
}

.svg_blue {
  fill: #374099;
}
.client_nav__dropdown {
  font-size: 30px;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 400;
}
.client_overview__nav {
  box-shadow: 0 2px 5px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}
.client_nav__dropdown:hover {
  background-color: #e6e6e6;
  font-weight: 600;
}

.client__name__nav {
  top: 35px;
  left: 90px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  width: auto;
  border-radius: 5px;
  padding: 10px 0;
}

.client__name__nav ul {
  list-style: none;
}

.client__name__nav ul li {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}

.client__name__nav ul li:hover {
  background-color: #e6e6e6;
}

.client_page__add_new_checklist {
  font-size: 40px;
  padding: 5px;
  border: 2px dashed #adadad;
  border-radius: 5px;
  /* background: linear-gradient(45deg, #14a19d 10%, #2c4a90 90%) !important; */
}
.client_page__add_new_checklist {
  /* fill: #fff; */
}
.mb-5 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.client_details__section {
  padding: 50px 150px;
}

.fw-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-color-grey:hover {
  background-color: #f3f3f3;
  border-radius: 2px;
}
.px-5{
  padding-left: 3rem;
  padding-right: 3rem;
} 
.py-1{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.checklistModal {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  padding: 50px;
}

.font-size-30 {
  font-size: 30px;
}

.close-icon {
  cursor: pointer;
  padding: 5px;
}

.close-icon:hover {
  background-color: #ebebeb;
  border-radius: 3px;
}

.addNewChecklistIcon {
  font-size: 130px;
  padding: 41px;
  border: 1px dashed #000;
  border-radius: 20px;
  cursor: pointer;
}

#addNewClientTypeDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.justify-space-around {
  justify-content: space-around;
}

#addNewChecklistForm input {
  display: block;
  font-size: 16px;
  padding: 10px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}

#addNewChecklistForm label {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;
}

.btn .btn-primary {
  padding: 10px 20px;
  border: none;
  margin: 20px auto;
  background: linear-gradient(#303b8e, #12a29d);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.login_form {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%) !important;
  min-height: 50vh;
  width: 50%;
  padding: 50px;
}
.login_form input {
  padding: 5px;
  width: 40%;
}
.d-block {
  display: block;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-15 {
  margin-left: 20px;
}

.mr-15{
  margin-right: 20px;
}

.font-size-16{
  font-size: 16px !important;
}

.text-muted{
  color: #2d2d2d;
}

.color-blue{
  color: #374099;
}

.addNewClientModal{
  padding: 5% 20%;
}

.addNewClientDivForm{
  padding: 2% 20%;
}

#fullPageLoader{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #dedede6e;
  z-index: 999999;
}

.w-80{
  width: 80%;
}

#fullPageLoader div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}