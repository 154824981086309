.section_icon {
  cursor: pointer;
  margin-right: 10px;
}

.section_icon:hover {
  background-color: #dedede;
}
.taskTitle {
  min-width: 200px;
}
.taskTitle *[contentEditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
  color: red;
}

.task_section {
  margin-bottom: 30px;
}

.task_section__title {
  margin-bottom: 20px;
}

.addEventIcon {
    margin-left: 20px;

}

.addEventIcon{
    display: inline-block;
}